import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Die Casting",
  "description": null,
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Laser-Cutting", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Die casting is the process of melting metal and forcing it into a mould cavity, not unlike `}<a parentName="p" {...{
        "href": "/articles/Injection-Molding"
      }}>{`injection moulding`}</a>{` for plastics. The largest difference between the two is the fact that die casting uses metals, whereas plastic injection moulding uses plastic polymers.`}</p>
    <h3 {...{
      "id": "materials-used"
    }}>{`Materials Used`}</h3>
    <p>{`Both pure metals and alloys can be used for die casting. In terms of pure metals, you can use:`}</p>
    <ul>
      <li parentName="ul">{`Zinc,`}</li>
      <li parentName="ul">{`Aluminum, and`}</li>
      <li parentName="ul">{`Magnesium
when it comes to alloys, you can use:`}</li>
      <li parentName="ul">{`Zinc,`}</li>
      <li parentName="ul">{`Aluminum,`}</li>
      <li parentName="ul">{`Magnesium, and`}</li>
      <li parentName="ul">{`Copper alloys`}</li>
    </ul>
    <p>{`There are two main types of die casting. They are called hot chamber and cold chamber die casting.`}</p>
    <h3 {...{
      "id": "chamber-types"
    }}>{`Chamber Types`}</h3>
    <p>{`There are primarily two types of chambers in die casting:`}</p>
    <ol>
      <li parentName="ol">{`Hot Chamber Die Casting`}</li>
      <li parentName="ol">{`Cold Chamber Die Casting`}</li>
    </ol>
    <p>{`In hot chamber die casting, the metal is molten inside the cast, while in cold chamber die casting, the metal is molten in a separate furnace. This is the biggest difference between hot chamber and cold chamber die casting. Both technologies have their advantages.`}</p>
    <ul>
      <li parentName="ul">{`Processing time of a hot chamber die casting setup is faster than a cold chamber. One cycle takes around 15-20 minutes.`}</li>
      <li parentName="ul">{`When used in conjunction with low melting point metals and alloys such as copper or zinc, you get more life out of your dies. But you're putting more stress on the machinery.`}</li>
      <li parentName="ul">{`Cold chamber die casting is more suitable for metals and alloys with a higher melting points.`}</li>
    </ul>
    <h3 {...{
      "id": "when-should-you-use-die-casting"
    }}>{`When Should You Use Die Casting?`}</h3>
    <p>{`Die casting is commonly used to produce `}<strong parentName="p">{`complex-shaped`}</strong>{` metal structures. Due to the start-up costs associated with die casting moulds, it is more suitable for large batches of production due to the high startup costs. Expect to be manufacturing at least a few hundred parts a year in order to amortize the costs associated with preparing the dies. If you need mass produced parts that fall within tight tolerances, die casting may be the right choice for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      